





import { Component, Vue, Prop, Watch } from 'nuxt-property-decorator';
import { gsap } from 'gsap';

import { SplitText } from '@/assets/js/gsap/SplitText';
gsap.registerPlugin(SplitText);

@Component
export default class AnimateHeading extends Vue {
  @Prop(Boolean)
    appear!: boolean;

  @Prop({ type: Number, default: 1.75 })
    duration!: number;

  @Prop({ type: Number, default: 0 })
    delay!: number;

  @Prop({ type: Number, default: 0.1 })
    stagger!: number;

  @Prop({ type: Boolean, default: true })
    resplitOnResize!: boolean;

  outerSplit;
  overflowSplit;
  innerSplit;
  el;
  hasAnimated = false;

  get appIsReady () {
    return this.$store.state.app.isReady;
  }

  @Watch('appIsReady')
  @Watch('appear')
  animateonChange () {
    if (this.appIsReady && this.appear)
      this.animateIn();
  }

  mounted () {
    this.el = this.$refs.el as HTMLElement;
    if (this.resplitOnResize)
      this.$nuxt.$on('onResize', this.onResize);
    this.$nextTick(() => {
      this.initSplitText();
    });

    if (this.appIsReady && this.appear)
      this.animateIn();
  }

  beforeDestroy () {
    this.revertSplitText();
    this.$nuxt.$off('onResize', this.onResize);
  }

  initSplitText () {
    // Order (inner -> outer) is important for nesting
    this.innerSplit = new SplitText(this.el, { type: 'lines', linesClass: 'animate-heading_inner' });
    this.overflowSplit = new SplitText(this.el, { type: 'lines', linesClass: 'animate-heading_overflow' });
    this.outerSplit = new SplitText(this.el, { type: 'lines', linesClass: 'animate-heading_outer' });
  }

  revertSplitText () {
    // Reverse the order (outer -> inner)
    this.outerSplit.revert();
    this.overflowSplit.revert();
    this.innerSplit.revert();
  }

  animateIn () {
    this.$nextTick(() => {
      const tl = gsap.timeline();
      tl.set(this.innerSplit.lines, { yPercent: 90, opacity: 0 })
        .set(this.el, { opacity: 1 })
        .to(this.outerSplit.lines, { '--border-scale': 1, duration: 1, stagger: this.stagger * 2, ease: 'expo.inOut' })
        .addLabel('run', '-=0.75')
        .to(this.innerSplit.lines, { opacity: 1, duration: 0.25, stagger: this.stagger }, 'run')
        .to(this.innerSplit.lines, { rotation: 0.01, yPercent: 0, duration: 1.75, stagger: this.stagger, ease: 'expo.out' }, 'run')
        .call(() => { this.hasAnimated = true }, [], 'run');
    });
  }

  onResize () {
    this.revertSplitText();
    this.initSplitText();
  }
}
